import { Outlet } from 'react-router-dom'

import { Aside, Loading } from '@/components'

import { useAppSelector } from '../store/hooks'

export const ServiceLayout = () => {
	const loading = useAppSelector((state) => state.app.loading)
	const isInit = useAppSelector((state) => state.app.init)

	if (!isInit) {
		return <Loading />
	}

	return (
		<>
			{loading ? <Loading /> : ''}

			<main className="main">
				<Aside />
				<div className="main__right">
					<Outlet />
				</div>
			</main>
		</>
	)
}
