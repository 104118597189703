import { Outlet, useLocation } from 'react-router-dom'
import { isCallModalIsOpen } from 'store/appReducer'
import { useAppDispatch, useAppSelector } from 'store/hooks'

import { CallModal, TimeoutProvider } from '@/components'

export const MainLayout = () => {
	const callModalIsOpen = useAppSelector((state) => state.app.callModalIsOpen)
	const dispatch = useAppDispatch()
	const location = useLocation()

	const switchCallMode = () => {
		const path = location.pathname
		if (path === '/' || path === '/begin') {
			return '_begin'
		}
		if (path.includes('service')) {
			return '_service'
		}
		return 'error'
	}

	const closeModal = () => {
		dispatch(isCallModalIsOpen(false))
	}
	return (
		<>
			<TimeoutProvider>
				<Outlet />
			</TimeoutProvider>
			{callModalIsOpen && (
				<CallModal
					close={closeModal}
					open={callModalIsOpen}
					addingClass={switchCallMode}
				/>
			)}
		</>
	)
}
