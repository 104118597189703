import cn from 'clsx'
import React from 'react'
import { useNavigate } from 'react-router-dom'

interface Props {
	title: string
	videoUrl?: string
	className?: string
}

export const Advertising: React.FC<Props> = ({
	title,
	videoUrl,
	className,
}) => {
	const navigate = useNavigate()

	return (
		<div className={cn('advertising', className)}>
			<button className="back-btn" onClick={() => navigate(-1)}>
				Вернуться назад
			</button>
			<h1 className="title">{title}</h1>
			<video src={videoUrl}></video>
		</div>
	)
}
