import {
	CloseOutlined,
	FullscreenExitOutlined,
	FullscreenOutlined,
} from '@ant-design/icons'
import { Button } from 'antd'
import { HOST_WEBRTC } from 'configs/url'
import { useEffect, useState } from 'react'

export function CallModal({
	close,
	open,
	addingClass,
}: {
	close: () => void
	open: boolean
	addingClass: () => string
}) {
	const [isBig, setIsBig] = useState(true)

	const onClose = () => {
		close()
	}

	useEffect(() => {
		const listener = (event: any) => {
			if (event.data && event.data.type) {
				if (event.data.type === 'close-iframe') {
					const isSuccess = event.data.success
					if (!isSuccess) {
						close()
					}
				}
			}
		}

		window.addEventListener('message', listener, false)
		return window.addEventListener('message', listener, false)
	}, [])

	return (
		<div
			className={`call-modal ${open && '_open'} ${!isBig && '_small'} ${addingClass()}`}
		>
			<div className="call-modal__inner">
				<div className="call-modal__top">
					<Button
						size={isBig ? 'large' : 'middle'}
						type="primary"
						className="call-modal__btn"
						onClick={() => setIsBig((old) => !old)}
						icon={isBig ? <FullscreenExitOutlined /> : <FullscreenOutlined />}
					></Button>
					<Button
						className="call-modal__btn"
						type="primary"
						size={isBig ? 'large' : 'middle'}
						danger
						onClick={onClose}
						icon={<CloseOutlined />}
					></Button>
				</div>
				<div className="call-modal__video">
					<iframe
						src={HOST_WEBRTC}
						style={{ width: '100%', height: '100%' }}
						allow="camera; microphone; fullscreen"
						loading="lazy"
					></iframe>
				</div>
			</div>
		</div>
	)
}
