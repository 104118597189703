import React, { useEffect, useState } from 'react'
import { RouterProvider } from 'react-router-dom'

import router from './routes'
import { initializeApp, setTypeApp, useAppDispatch } from './store'
import { typeAppDesktop, typeAppMobile } from './utils/const'

function MainApp() {
	const dispatch = useAppDispatch()

	const [width, setWidth] = useState<number>(window.innerWidth)

	const routers = router

	useEffect(() => {
		if (width < 769) {
			dispatch(setTypeApp(typeAppMobile))
		}
		dispatch(initializeApp())
	}, [dispatch])

	window.onresize = () => {
		setWidth(window.innerWidth)
		if (width < 769) {
			dispatch(setTypeApp(typeAppMobile))
		} else {
			dispatch(setTypeApp(typeAppDesktop))
		}
	}

	return <RouterProvider router={routers} />
}

export default MainApp
