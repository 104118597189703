import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import { authAPI } from '../api'

import { loadingStatus } from './appReducer'

export interface InitialStateType {
	isAuth: boolean
}

const initialState: InitialStateType = {
	isAuth: false,
}

export const authReducer = createSlice({
	name: 'auth',
	initialState,
	reducers: {
		setAuth: (state, action: PayloadAction<boolean>) => {
			state.isAuth = action.payload
		},
	},
})

export const { setAuth } = authReducer.actions

export const login =
	({ email, password }: { email: string; password: string }) =>
	async (dispatch: any) => {
		dispatch(loadingStatus(true))

		try {
			await authAPI.login(email, password)

			dispatch(setAuth(true))
		} catch (err: any) {
			console.error(err)
		}

		dispatch(loadingStatus(false))
	}

export const logOut = () => async (dispatch: any) => {
	dispatch(loadingStatus(true))

	try {
		dispatch(setAuth(false))
	} catch (err: any) {
		console.error(err)
	}

	dispatch(loadingStatus(false))
}

export default authReducer.reducer
