import { HOST_WEBRTC } from 'configs/url'

export const CallPage = () => {
	return (
		<div className="callPage">
			<div className="callPage__title title">Звонок оператору</div>
			<iframe
				src={HOST_WEBRTC}
				style={{ width: '100%', height: '100%' }}
				allow="camera; microphone; fullscreen"
				loading="lazy"
			></iframe>
		</div>
	)
}
