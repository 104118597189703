import { QuestionCircleOutlined } from '@ant-design/icons'
import { Button, Modal } from 'antd'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Link } from 'react-router-dom'
import Keyboard from 'react-simple-keyboard'

import { START_PATH } from '../utils/const'
import {
	displayLetters,
	displaySt,
	domainsLayout,
	layoutLetters,
	layoutSt,
} from '../utils/typeKeyBoard'
import {
	emailValidNotRequired,
	numberValid,
	personalNumber,
} from '../utils/validators/validators'

type Inputs = {
	summa: string
	personalNumber: string
	mail: string
}

export const Cardpage = () => {
	const [isModalOpen, setIsModalOpen] = useState(false)
	const [currentInput, setCurrentInput] = useState<null | string>(null)
	const [isCorrect, setIsCorrect] = useState<boolean>(false)
	const [open, setOpen] = useState(false)
	const [typeKeyboard, setTypeKeyboard] = useState<string>('numbers')

	const {
		register,
		control,
		getValues,
		trigger,
		setValue,
		watch,
		formState: { errors },
	} = useForm<Inputs>()

	const onFocus = (name: string) => {
		if (currentInput && currentInput !== name) {
			//@ts-ignore
			trigger(currentInput)
		}
		setCurrentInput(name)
	}

	const onSubmit = (data: any) => {
		trigger().then((result) => {
			if (result) {
				console.log(data)
			}
		})
	}

	const onKeyPress = (button: any) => {
		if (button === '{enter}') {
			onSubmit(getValues())
		} else {
			if (currentInput) {
				if (button === '{bksp}') {
					//@ts-ignore
					setValue(currentInput, `${getValues(currentInput).slice(0, -1)}`)
				} else {
					//@ts-ignore
					setValue(currentInput, `${getValues(currentInput)}${button}`)
				}
			}
		}
	}

	useEffect(() => {
		if (currentInput === 'mail') {
			setTypeKeyboard('text')
		} else {
			setTypeKeyboard('numbers')
		}
	}, [currentInput])

	useEffect(() => {
		setCurrentInput('personalNumber')
	}, [])
	return (
		<>
			<div className={`transfer payment ${typeKeyboard === 'text' && '_text'}`}>
				<div className="transfer__top">
					<Button
						className="transfer__info"
						type="primary"
						shape="circle"
						style={{ width: '50px', height: '50px' }}
						size="large"
						onClick={() => setIsModalOpen(true)}
						icon={<QuestionCircleOutlined style={{ fontSize: '26px' }} />}
					></Button>

					<h2 className="transfer__title title">Оплата газа картой</h2>
					<form className="transfer__form">
						<ul className="transfer__list">
							<li className="transfer__item">
								<label className="transfer__label" htmlFor="personalNumber">
									Номер лицевого счета
								</label>
								<div className="transfer__input-wrap">
									<div className="transfer__input-inner">
										<input
											className={`transfer__input ${errors.personalNumber ? '_error' : ''}`}
											id="personalNumber"
											type="text"
											placeholder="123456789012"
											onFocus={() => onFocus('personalNumber')}
											{...register('personalNumber', personalNumber)}
										/>
									</div>

									<div className="transfer__input-text">
										Введите 8 или 12 цифр лицевого счета
									</div>
								</div>
								<div className="transfer__input-error">
									{errors.personalNumber && errors.personalNumber.message}
								</div>
							</li>
							<li className="transfer__item">
								<label className="transfer__label" htmlFor="summa">
									Сумма платежа
								</label>
								<div className="transfer__input-wrap">
									<div className="transfer__input-inner">
										<input
											className={`transfer__input ${errors.summa ? '_error' : ''}`}
											id="summa"
											type="text"
											placeholder="123456789012"
											onFocus={() => onFocus('summa')}
											{...register('summa', numberValid)}
										/>
									</div>

									<div className="transfer__input-text">
										Введите сумма плтежа с точностью до рубля
									</div>
								</div>
								<div className="transfer__input-error">
									{errors.summa && errors.summa.message}
								</div>
							</li>
							<li className="transfer__item">
								<label className="transfer__label" htmlFor="mail">
									Электронная почта
								</label>
								<div className="transfer__input-wrap">
									<div className="transfer__input-inner">
										<input
											className={`transfer__input ${errors.mail ? '_error' : ''}`}
											id="mail"
											type="text"
											placeholder="example@example.ru"
											onFocus={() => onFocus('mail')}
											{...register('mail', emailValidNotRequired)}
										/>
									</div>

									<div className="transfer__input-text">Почта</div>
								</div>
								<div className="transfer__input-error">
									{errors.mail && errors.mail.message}
								</div>
							</li>
						</ul>
					</form>
					<button
						className="transfer__btn btn"
						onClick={() => onSubmit(getValues())}
						disabled={!isCorrect}
					>
						Продолжить
					</button>
				</div>

				<div className="transfer__bottom ">
					{typeKeyboard === 'numbers' && (
						<Keyboard
							onKeyPress={onKeyPress}
							layout={layoutSt}
							display={displaySt}
						/>
					)}
					{typeKeyboard === 'text' && (
						<div style={{ display: 'flex' }}>
							<Keyboard
								baseClass={'simple-keyboard-numpadStart'}
								onKeyPress={onKeyPress}
								layout={layoutLetters}
								display={displayLetters}
							/>
							<Keyboard
								baseClass={'simple-keyboard-numpadEnd'}
								mergeDisplay={true}
								onKeyPress={onKeyPress}
								layout={domainsLayout}
								display={displayLetters}
							/>
						</div>
					)}
				</div>
			</div>
			<Modal
				title="Оплата с помощью карты"
				open={isModalOpen}
				width={'800px'}
				onCancel={() => setIsModalOpen(false)}
				footer={[
					<Button type="primary" onClick={() => setIsModalOpen(false)}>
						Закрыть
					</Button>,
				]}
			>
				<p>
					Оплата банковской картой
					<br />
					БЕЗ КОМИССИИ
				</p>
			</Modal>
			<Modal
				open={open}
				centered
				title="Оплата прошла успешно"
				footer={[
					<div className="transfer__btn-wrap">
						<Link to={START_PATH} className="btn transfer__btn-modal">
							Вернуться на главную
						</Link>
					</div>,
				]}
			>
				<div className="transfer__modal-top">
					<div className="transfer__modal-inner">
						<div className="transfer__modal-title">Лицевой счет:</div>
						<div className="transfer__modal-text">234432</div>
					</div>
					<div className="transfer__modal-inner">
						<div className="transfer__modal-title">Показания счетчика:</div>
						<div className="transfer__modal-text">132312</div>
					</div>
				</div>
				<div className="transfer__modal-inner">
					<div className="transfer__modal-title">
						Ваш чек отправлен по адресу:
					</div>
					<div className="transfer__modal-text">234234</div>
				</div>
			</Modal>
		</>
	)
}
