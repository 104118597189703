import { ConfigProvider } from 'antd'
import React from 'react'
import ReactDOM from 'react-dom/client'
import { Provider } from 'react-redux'

import MainApp from './MainApp'
import { TimeoutProvider } from './components/Providers/TimeoutProvider'
import './index.scss'
import { store } from './store'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
	<React.StrictMode>
		<ConfigProvider
			theme={{
				token: {
					colorPrimary: '#3690e4',
				},
			}}
		>
			<Provider store={store}>
				<MainApp />
			</Provider>
		</ConfigProvider>
	</React.StrictMode>
)
