import { Link } from 'react-router-dom'

import SBPImage from '../assets/images/sbp.svg'
import CardImage from '../assets/images/v.svg'
import { PAYMENT_CARD_PATH, PAYMENT_SBP_PATH } from '../utils/const'

export const Payment = () => {
	return (
		<div className="payment">
			<h2 className="payment__title title">Оплата газа</h2>
			<ul className="payment__inner">
				<li className="payment__item">
					<Link to={PAYMENT_SBP_PATH} className="payment__item-link">
						<div className="payment__item-title">
							Оплата через СБП (по QR-коду) <br></br> БЕЗ КОМИССИИ
						</div>
						<div className="payment__logo-wrap">
							<img className="payment__logo" src={SBPImage} alt="payment" />
						</div>

						<div className="payment__link btn">Оплата через СБП</div>
					</Link>
					<Link to={'2'} className="payment__instruction">
						Инструкция для оплаты по QR-коду
					</Link>
				</li>
				<li className="payment__item">
					<Link to={PAYMENT_CARD_PATH} className="payment__item-link">
						<div className="payment__item-title">
							Оплата банковской картой<br></br> БЕЗ КОМИССИИ
						</div>
						<div className="payment__logo-wrap">
							<img className="payment__logo" src={CardImage} alt="payment" />
						</div>
						<div className="payment__link btn">Оплата картой</div>
					</Link>
				</li>
			</ul>
		</div>
	)
}
