import { QuestionCircleOutlined } from '@ant-design/icons'
import { Button, Modal } from 'antd'
import React, { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import MaskedInput from 'react-input-mask'
import { Link } from 'react-router-dom'
import Keyboard from 'react-simple-keyboard'
import 'react-simple-keyboard/build/css/index.css'

import { RECEIVE_PATH, START_PATH } from '../utils/const'
import { displaySt, layoutSt } from '../utils/typeKeyBoard'
import {
	indicationsValid,
	personalNumber,
	phoneField,
} from '../utils/validators/validators'

type Inputs = {
	phone: string
	personalNumber: string
	indications: string
}

export const Transfer = () => {
	const [isModalOpen, setIsModalOpen] = useState(false)
	const [open, setOpen] = useState(false)

	const [currentInput, setCurrentInput] = useState<null | string>(null)
	const [isCorrect, setIsCorrect] = useState<boolean>(false)
	const {
		register,
		control,
		getValues,
		trigger,
		setValue,
		watch,
		formState: { errors },
	} = useForm<Inputs>()

	const onSubmit = (data: any) => {
		trigger().then((result) => {
			if (result) {
				console.log(data)
			} else {
				if (getValues('phone').length > 14) {
					console.log(getValues('phone').length)
					setValue('phone', '')
				}
			}
		})
	}

	const onKeyPress = (button: any) => {
		if (button === '{enter}') {
			onSubmit(getValues())
		} else {
			if (currentInput) {
				if (button === '{bksp}') {
					//@ts-ignore
					setValue(currentInput, `${getValues(currentInput).slice(0, -1)}`)
				} else {
					//@ts-ignore
					setValue(currentInput, `${getValues(currentInput)}${button}`)
					//@ts-ignore
					console.log(getValues(currentInput), button)
				}
			}
		}
	}

	const onFocus = (name: string) => {
		if (currentInput && currentInput !== name) {
			//@ts-ignore
			trigger(currentInput)
		}
		setCurrentInput(name)
	}

	useEffect(() => {
		setCurrentInput('personalNumber')
	}, [])

	return (
		<>
			<div className="transfer">
				<div className="transfer__top">
					<Button
						className="transfer__info"
						type="primary"
						shape="circle"
						style={{ width: '50px', height: '50px' }}
						size="large"
						onClick={() => setIsModalOpen(true)}
						icon={<QuestionCircleOutlined style={{ fontSize: '26px' }} />}
					></Button>

					<h2 className="transfer__title title">Передать показания</h2>
					<form className="transfer__form">
						<ul className="transfer__list">
							<li className="transfer__item">
								<label className="transfer__label" htmlFor="personalNumber">
									Номер лицевого счета
								</label>
								<div className="transfer__input-wrap">
									<div className="transfer__input-inner">
										<input
											className={`transfer__input ${errors.personalNumber ? '_error' : ''}`}
											id="personalNumber"
											type="text"
											placeholder="123456789012"
											onFocus={() => onFocus('personalNumber')}
											{...register('personalNumber', personalNumber)}
										/>
									</div>

									<div className="transfer__input-text">
										Введите 8 или 12 цифр лицевого счета
									</div>
								</div>
								<div className="transfer__input-error">
									{errors.personalNumber && errors.personalNumber.message}
								</div>
							</li>
							<li className="transfer__item">
								<label className="transfer__label" htmlFor="phone">
									Номер телефона
								</label>
								<div className="transfer__input-wrap">
									<div className="transfer__input-inner">
										<Controller
											name={'phone'}
											control={control}
											defaultValue=""
											rules={phoneField}
											render={({ field }) => (
												<MaskedInput
													mask="+7 (999) 999-99-99"
													maskChar=""
													value={field.value ? field.value : ''}
													onChange={field.onChange}
													className={`transfer__input `}
													id="phone"
													onBlur={field.onBlur}
													onFocus={() => onFocus('phone')}
													placeholder={'+7 (999) 999-99-99'}
												></MaskedInput>
											)}
										/>
									</div>

									<div className="transfer__input-text">
										Введите ваш контактный телефон
									</div>
								</div>
								<div className="transfer__input-error">
									{errors.phone && errors.phone.message}
								</div>
							</li>
							<li className="transfer__item">
								<label className="transfer__label" htmlFor="indications">
									Показания счётчика
								</label>
								<div className="transfer__input-wrap">
									<div className="transfer__input-inner">
										<input
											className={`transfer__input ${errors.indications ? '_error' : ''}`}
											id="indications"
											type="text"
											placeholder="12345"
											onFocus={() => onFocus('indications')}
											{...register('indications', indicationsValid)}
										/>
									</div>

									<div className="transfer__input-text">
										Введите текущие показания газового счётчкиа до запятой, не
										более 5 символов
									</div>
								</div>
								<div className="transfer__input-error">
									{errors.indications && errors.indications.message}
								</div>
							</li>
						</ul>
					</form>
					<button
						className="transfer__btn btn"
						onClick={() => onSubmit(getValues())}
						disabled={!isCorrect}
					>
						Продолжить
					</button>
				</div>

				<div className="transfer__bottom ">
					<Keyboard
						onKeyPress={onKeyPress}
						layout={layoutSt}
						display={displaySt}
					/>
				</div>
			</div>
			<Modal
				title="Передать показания информация"
				open={isModalOpen}
				width={800}
				onCancel={() => setIsModalOpen(false)}
				footer={[
					<Button type="primary" onClick={() => setIsModalOpen(false)}>
						Закрыть
					</Button>,
				]}
			>
				<p>
					Сервис приема показаний счетчика в данном терминале является средством
					для приема показаний приборов учета газа от абонентов ООО «Газпром
					межрегионгаз Оренбург».
				</p>{' '}
				<p>
					Показания счетчиков абонентов, направленные в электронном виде в
					данном терминале принимаются к учету в соответствии с действующим
					законодательством. Информация о персональных данных абонентов,
					направивших показания приборов учета газа в электронном виде, хранится
					и обрабатывается в соответствии с требованиями Российского
					законодательства о защите персональных данных. С целью корректной
					обработки поступающих показаний приборов, просим указывать точные и
					полные данные. Обратите внимание, что все поля являются обязательными
					для заполнения. Ваш номер телефона может быть использован для
					уточнения введенных вами данных.
				</p>
			</Modal>
			<Modal
				open={open}
				centered
				title="Показания переданы успешно"
				footer={[
					<div className="transfer__btn-wrap">
						<Link to={START_PATH} className="btn transfer__btn-modal">
							Вернуться на главную
						</Link>
						,
						<Link to={RECEIVE_PATH} className="btn transfer__btn-modal">
							Получить счета на e-mail
						</Link>
						,
					</div>,
				]}
			>
				<div className="transfer__modal-top">
					<div className="transfer__modal-inner">
						<div className="transfer__modal-title">Лицевой счет:</div>
						<div className="transfer__modal-text">234432</div>
					</div>
					<div className="transfer__modal-inner">
						<div className="transfer__modal-title">Показания счетчика:</div>
						<div className="transfer__modal-text">132312</div>
					</div>
				</div>
				<div className="transfer__modal-inner">
					<div className="transfer__modal-title">телефон</div>
					<div className="transfer__modal-text">234234</div>
				</div>
			</Modal>
		</>
	)
}
