import {
	ContainerOutlined,
	CreditCardOutlined,
	CustomerServiceOutlined,
	SnippetsOutlined,
	UserOutlined,
} from '@ant-design/icons'
import { Link, NavLink } from 'react-router-dom'
import { isCallModalIsOpen } from 'store/appReducer'
import { useAppDispatch } from 'store/hooks'

import {
	GASIFICATION_PATH,
	PAYMENT_PATH,
	PROFILE_PATH,
	RECEIVE_PATH,
	START_PATH,
	TRANSFER_PATH,
} from '@/utils'

import LogoGazification from '../../assets/images/dogaz.svg'
import LogoGazificationBlue from '../../assets/images/dogazblue.svg'
import Logo from '../../assets/images/logo_main_wh.png'

export const Aside = () => {
	const dispatch = useAppDispatch()
	const setCallModalIsOpen = () => {
		dispatch(isCallModalIsOpen(true))
	}
	return (
		<aside className="aside">
			<div className="aside__top-wrap">
				<div className="aside__top">
					<Link className="aside__img-wrap" to={START_PATH}>
						<img className="aside__img" src={Logo} alt="logo" />
					</Link>
					<div className="aside__logo-text">Интерактивный прием абонентов</div>
				</div>
				<ul className="aside__list">
					<li className="aside__item">
						<NavLink to={TRANSFER_PATH} className={'aside__link'}>
							<div className="BeginPage__img-wrap">
								<SnippetsOutlined style={{ color: '#fff', fontSize: '35px' }} />
							</div>
							<div className="BeginPage__right">
								<div className="BeginPage__item-title">Передать показания</div>
								<div className="BeginPage__item-descr">
									Передайте показания газового счетчика через терминад
								</div>
							</div>
						</NavLink>
					</li>
					<li className="aside__item">
						<NavLink to={PAYMENT_PATH} className={'aside__link'}>
							<div className="BeginPage__img-wrap">
								<CreditCardOutlined
									style={{ color: '#fff', fontSize: '35px' }}
								/>
							</div>
							<div className="BeginPage__right">
								<div className="BeginPage__item-title">Оплата газа</div>
								<div className="BeginPage__item-descr">
									Оплатите услуги газа через терминал БЕЗ КОМИССИИ
								</div>
							</div>
						</NavLink>
					</li>
					<li className="aside__item">
						<NavLink to={RECEIVE_PATH} className={'aside__link'}>
							<div className="BeginPage__img-wrap">
								<ContainerOutlined
									style={{ color: '#fff', fontSize: '35px' }}
								/>
							</div>
							<div className="BeginPage__right">
								<div className="BeginPage__item-title">
									Получить Счета на E-mail
								</div>
								<div className="BeginPage__item-descr">
									Оформите заявку на получение счета за газ на электронную почту
								</div>
							</div>
						</NavLink>
					</li>
					<li className="aside__item">
						<NavLink to={PROFILE_PATH} className={'aside__link'}>
							<div className="BeginPage__img-wrap">
								<UserOutlined style={{ color: '#fff', fontSize: '35px' }} />
							</div>
							<div className="BeginPage__right">
								<div className="BeginPage__item-title">Личный кабинет</div>
								<div className="BeginPage__item-descr">
									Узнайте о возможностях личного кабинета
								</div>
							</div>
						</NavLink>
					</li>
					<li className="aside__item">
						<NavLink to={GASIFICATION_PATH} className={'aside__link'}>
							<div className="BeginPage__img-wrap">
								<img
									className="BeginPage__img _blue"
									src={LogoGazificationBlue}
									alt=""
								/>
								<img
									className="BeginPage__img _white"
									src={LogoGazification}
									alt=""
								/>
							</div>
							<div className="BeginPage__right">
								<div className="BeginPage__item-title">Догазификация</div>
								<div className="BeginPage__item-descr">
									Подключите свое домовладение к газовой сети на льготных
									условиях
								</div>
							</div>
						</NavLink>
					</li>
				</ul>
			</div>
			<button className="aside__bottom" onClick={setCallModalIsOpen}>
				<div className="aside__bottom-img-wrap">
					<CustomerServiceOutlined
						style={{ color: '#fff', fontSize: '55px' }}
					/>
				</div>
				<div className="aside__bottom-text">Видеозвонок оператору</div>
			</button>
		</aside>
	)
}
