export const phoneField = {
	required: 'Поле обязательно для заполнение',
	maxLength: {
		value: 14,
		message: 'Недопустимый формат',
	},
	minLength: {
		value: 14,
		message: 'Недопустимый формат',
	},
}

export const personalNumber = {
	required: 'Поле обязательно для заполнение',
	pattern: {
		value: /^([0-9]{8}|[0-9]{12})$/i,
		message: '8 или 12 цифр',
	},
}
export const indicationsValid = {
	required: 'Поле обязательно для заполнение',
	maxLength: {
		value: 5,
		message: 'Не более 5 символов',
	},
	minLength: {
		value: 1,
		message: 'Минимум 1 символ',
	},
}

export const numberValid = {
	required: 'Поле обязательно для заполнение',
	pattern: {
		value: /^[0-9]+$/i,
		message: 'Только цифры',
	},
}

export const emailValid = {
	required: 'Поле обязательно для заполнение',
	pattern: {
		value:
			/^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i,
		message: 'Некорректный адрес почты',
	},
}
export const emailValidNotRequired = {
	pattern: {
		value:
			/^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i,
		message: 'Некорректный адрес почты',
	},
}
