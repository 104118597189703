import { Link } from 'react-router-dom'

import Logo from '../assets/images/logo_main_wh.png'
import { START_PATH } from '../utils/const'

export const StartPage = () => {
	return (
		<Link to={START_PATH} className="startScrenn">
			<div className="startScrenn__inner">
				<img className="startScrenn__logo" src={Logo} alt="" />
				<div className="startScrenn__text">Приём абонентов и оплата за газ</div>
				<span className="startScrenn__link">
					Для начала работы коснитесь экрана
				</span>
			</div>
		</Link>
	)
}
