export const layoutSt = {
	default: ['1 2 3 {bksp}', '4 5 6 {enter}', '7 8 9 ', ' 0  '],
}
export const displaySt = {
	'{bksp}': 'Стереть ⌫',
	'{enter}': 'Отправить ↵',
}

export const layoutLetters = {
	default: [
		'1 2 3 4 5 6 7 8 9 0 - . _',
		'q w e r t y u i o p @',
		'a s d f g h j k l .ru',
		'z x c v b n m .com',
	],
}
export const domainsLayout = {
	default: [
		'{bksp}',
		'{enter}',
		'@yandex.ru @mail.ru',
		'@rambler.ru @gmail.com',
	],
}
export const displayLetters = {
	'{bksp}': 'Стереть ⌫',
	'{enter}': 'Отправить  ↵',
	'{.com}': '.com',
	'{.ru}': '.ru',
	'{@yandex.ru}': '@yandex.ru',
	'{@rambler.ru}': '@rambler.ru',
	'{@mail.ru}': '@mail.ru',
	'{@gmail.com}': '@gmail.com',
	'{//}': ' ',
}
